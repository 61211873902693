import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { useRef, useState } from 'react';
import ReactDOM from 'react-dom';

export const WorkshopListState = ({ workshops }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const iconRef = useRef(null);

  const handleMouseEnter = () => {
    if (iconRef.current) {
      const rect = iconRef.current.getBoundingClientRect();
      setTooltipPosition({
        top: rect.top - 20,
        left: rect.left + window.scrollX + rect.width + 8, // Ajout d'un espace de 8px à droite de l'icône
      });
      setTooltipVisible(true);
    }
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  if (!workshops) return null;

  return (
    <div className='flex flex-col w-full gap-2 p-2 mt-2'>
      <div className='flex items-center gap-2'>
        <span className='py-2 text-sm font-semibold text-white'>Etat des chantiers</span>
        <div
          ref={iconRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className='relative flex items-center justify-center w-4 h-4 bg-gray-500 rounded-full cursor-pointer'
        >
          <QuestionMarkCircleIcon className='w-3 h-3 text-white' />
          {tooltipVisible && (
            <TooltipPortal>
              <span
                style={{ top: tooltipPosition.top, left: tooltipPosition.left }}
                className='absolute z-10 p-2 text-xs text-white bg-black rounded w-max'
              >
                Indique si les caméras d&apos;un chantier ont été calibrées pour la journée en cours.
              </span>
            </TooltipPortal>
          )}
        </div>
      </div>
      {workshops.map((workshop) => {
        const indicatorColor = workshop.ready ? '#2CEAA8' : '#F9224B';
        return (
          <div key={workshop.id} className='relative flex items-center'>
            <div className='flex items-center gap-2'>
              <span>-</span> <span className='mb-1 mr-2 text-xs text-white'>{workshop.name}</span>
            </div>
            <span className='relative flex w-3 h-3'>
              <span
                className='absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping'
                style={{ backgroundColor: indicatorColor }}
              />
              <span className='relative inline-flex w-3 h-3 rounded-full' style={{ backgroundColor: indicatorColor }} />
            </span>
          </div>
        );
      })}
    </div>
  );
};

const TooltipPortal = ({ children }) => {
  return ReactDOM.createPortal(children, document.getElementById('tooltip-root'));
};

export default TooltipPortal;
